export const USA_STATES = [
  { id: "1", label: "Alabama/Montgomery", value: "Montgomery" },
  { id: "2", label: "Alaska/Juneau", value: "Juneau" },
  { id: "3", label: "Arizona/Phoenix", value: "Phoenix" },
  { id: "4", label: "Arkansas/Little Rock", value: "Little Rock" },
  { id: "5", label: "California/Sacramento", value: "Sacramento" },
  { id: "6", label: "Colorado/Denver", value: "Denver" },
  { id: "7", label: "Connecticut/Hartford", value: "Hartford" },
  { id: "8", label: "Delaware/Dover", value: "Dover" },
  { id: "9", label: "Florida/Tallahassee", value: "Tallahassee" },
  { id: "10", label: "Georgia/Atlanta", value: "Atlanta" },
  { id: "11", label: "Hawaii/Honolulu", value: "Honolulu" },
  { id: "12", label: "Idaho/Boise", value: "Boise" },
  { id: "13", label: "Illinois/Springfield", value: "Springfield" },
  { id: "14", label: "Indiana/Indianapolis", value: "Indianapolis" },
  { id: "15", label: "Iowa/Des Moines", value: "Des Moines" },
  { id: "16", label: "Kansas/Topeka", value: "Topeka" },
  { id: "17", label: "Kentucky/Frankfort", value: "Frankfort" },
  { id: "18", label: "Louisiana/Baton Rouge", value: "Baton Rouge" },
  { id: "19", label: "Maine/Augusta", value: "Augusta" },
  { id: "20", label: "Maryland/Annapolis", value: "Annapolis" },
  { id: "21", label: "Massachusetts/Boston", value: "Boston" },
  { id: "22", label: "Michigan/Lansing", value: "Lansing" },
  { id: "23", label: "Minnesota/Saint Paul", value: "Saint Paul" },
  { id: "24", label: "Mississippi/Jackson", value: "Jackson" },
  { id: "25", label: "Missouri/Jefferson City", value: "Jefferson City" },
  { id: "26", label: "Montana/Helena", value: "Helena" },
  { id: "27", label: "Nebraska/Lincoln", value: "Lincoln" },
  { id: "28", label: "Nevada/Carson City", value: "Carson City" },
  { id: "29", label: "New Hampshire/Concord", value: "Concord" },
  { id: "30", label: "New Jersey/Trenton", value: "Trenton" },
  { id: "31", label: "New Mexico/Santa Fe", value: "Santa Fe" },
  { id: "32", label: "New York/Albany", value: "Albany" },
  { id: "33", label: "North Carolina/Raleigh", value: "Raleigh" },
  { id: "34", label: "North Dakota/Bismarck", value: "Bismarck" },
  { id: "35", label: "Ohio/Columbus", value: "Columbus" },
  { id: "36", label: "Oklahoma/Oklahoma City", value: "Oklahoma City" },
  { id: "37", label: "Oregon/Salem", value: "Salem" },
  { id: "38", label: "Pennsylvania/Harrisburg", value: "Harrisburg" },
  { id: "39", label: "Rhode Island/Providence", value: "Providence" },
  { id: "40", label: "South Carolina/Columbia", value: "Columbia" },
  { id: "41", label: "South Dakota/Pierre", value: "Pierre" },
  { id: "42", label: "Tennessee/Nashville", value: "Nashville" },
  { id: "43", label: "Texas/Austin", value: "Austin" },
  { id: "44", label: "Utah/Salt Lake City", value: "Salt Lake City" },
  { id: "45", label: "Vermont/Montpelier", value: "Montpelier" },
  { id: "46", label: "Virginia/Richmond", value: "Richmond" },
  { id: "47", label: "Washington/Olympia", value: "Olympia" },
  { id: "48", label: "West Virginia/Charleston", value: "Charleston" },
  { id: "49", label: "Wisconsin/Madison", value: "Madison" },
  { id: "50", label: "Wyoming/Cheyenne", value: "Cheyenne" },
]
